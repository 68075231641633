import { Trans, useTranslation } from 'react-i18next';
import ButtonIcon from '@/assets/images/icons8.svg';
import windowLogo from '@/assets/images/windowsLogo.png';
import CheckIcon from '@/assets/images/check.svg';
import ArrowsIcon from '@/assets/images/arrows.svg';
import meetVideo from '@/assets/videos/meet.mp4';
import meetICon from '@/assets/videos/meet.jpg';
import youtubeVideo from '@/assets/videos/youtube.mp4';
import youtubeICon from '@/assets/videos/youtube.jpg';
import videoVideo from '@/assets/videos/video.mp4';
import videoICon from '@/assets/videos/video.jpg';
import './index.less';
import { downloadFile } from '@/utils';

function Introduction() {
  const { t } = useTranslation();
  const addChromeClick = () => {
    window.open(
      'https://chrome.google.com/webstore/detail/felo-subtitles-chatgpt-li/ponokiofkijoolhebggofhhibnafebna?utm_source=ext_app_menu',
    );
  };
  return (
    <div className="introduction-content">
      <div className="introduction-title">
        <h1 className="title-top">{t('live')}</h1>
        <div className="title-content">{t('AlTranslation')}</div>
        <div className="title-content">{t('everyWord')}</div>
        <div className="title-button-content">
          <div className="title-button-content-item">
            <a
              href="https://statistics-frontend.s3.ap-northeast-1.amazonaws.com/subtitles/prod/pc/2.4.0/Felo_Subtitles_2.4.0.exe"
              download="filename"
            >
              <div className="title-button">
                <img className="button-icon-two" src={windowLogo} />
                <div className="button-text">{t('Install')}</div>
              </div>
            </a>
            <div className="title-text">{t('Recommended')}</div>
          </div>
          <div className="title-button-content-item">
            <div className="title-button white-item" onClick={addChromeClick}>
              <img className="button-icon" src={ButtonIcon} />
              <div className="button-text">{t('addChrome')}</div>
            </div>
            <div className="title-text">{t('Recommendedplatforms')}</div>
          </div>
          <div className="product-button-item title-button-content-item">
            <a
              href="https://www.producthunt.com/products/felo-subtitles/reviews?utm_source=badge-product_review&utm_medium=badge&utm_souce=badge-felo&#0045;subtitles"
              target="_blank"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/product_review.svg?product_id=560735&theme=light"
                alt="Felo&#0032;Subtitles - Empower&#0032;meetings&#0032;with&#0032;real&#0045;time&#0032;transcription&#0032;&#0038;&#0032;translation&#0046; | Product Hunt"
                className="image-button"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="introduction-item-content" style={{ marginTop: '12vh' }}>
        <div className="item-icon">
          <video
            src={meetVideo}
            poster={meetICon}
            className="item-video"
            preload="auto"
            autoPlay
            playsInline
            webkit-playsinline
            muted
            loop
          ></video>
        </div>
        <div className="item-content">
          <div className="item-title">
            <Trans i18nKey="multilingualMeeting" />
          </div>
          <div className="item-content-text item-margin-top">{t('real-timeSubtitles')}</div>
          <div className="item-content-text">{t('threeLanguages')}</div>
          <div className="item-buttom-text item-margin-top">
            <img className="check-icon" src={CheckIcon} />
            {t('auto-capture')}
          </div>
          <div className="item-buttom-text">
            <img className="check-icon" src={CheckIcon} />
            {t('joinsMeeting')}
          </div>
        </div>
      </div>
      <div className="introduction-item-content">
        <div className="item-content">
          <div className="item-title">{t('YouTubeSubtitles')}</div>
          <div className="item-content-text item-margin-top">{t('bilingualSubtitles')}</div>
          <div className="item-content-text">{t('multipleLanguageAuto-detection')}</div>
          <div className="item-buttom-text item-margin-top">
            <img className="check-icon" src={CheckIcon} />
            {t('addCaptions')}
          </div>
          <div className="item-buttom-text">
            <img className="check-icon" src={CheckIcon} />
            {t('downloadSubtitles')}
          </div>
          <div className="item-buttom-text">
            <img className="check-icon" src={CheckIcon} />
            {t('setSeparately')}
          </div>
        </div>
        <div className="item-icon-right">
          <video
            src={youtubeVideo}
            poster={youtubeICon}
            className="item-video"
            preload="auto"
            autoPlay
            playsInline
            webkit-playsinline
            muted
            loop
          ></video>
        </div>
      </div>
      <div className="introduction-item-content-title">
        <div className="title">{t('works')}</div>
        <div className="content-text" style={{ marginTop: '40px' }}>
          {t('meetingCallStarts')}
        </div>
        <div className="content-text">{t('stopTranscribing')}</div>
      </div>
      <div className="introduction-item-content" style={{ marginBottom: '14vh' }}>
        <div className="item-icon">
          <video
            src={videoVideo}
            poster={videoICon}
            className="item-video"
            preload="auto"
            autoPlay
            playsInline
            webkit-playsinline
            muted
            loop
          ></video>
        </div>
        <div className="item-content" style={{ marginTop: '-30px' }}>
          <div className="item-step-content">
            <div className="step-text">{t('step')}</div>
            <div className="step-right">
              <div className="step-top">
                <div className="number-text">1</div>
                <div className="download-text" onClick={addChromeClick}>
                  {t('download')}
                </div>
              </div>
              <div className="step-bottom">{t('downloadChromeExtension')}</div>
            </div>
          </div>
          <div className="item-step-content">
            <div className="arrow-content">
              <img className="arrows-icon" src={ArrowsIcon} />
              <div className="step-text">{t('step')}</div>
            </div>
            <div className="step-right">
              <div className="step-top">
                <div className="number-text">2</div>
                <div className="buttom-text">{t('startMeet')}</div>
              </div>
              <div className="step-bottom">{t('StartMeeting')}</div>
            </div>
          </div>
          <div className="item-step-content">
            <div className="arrow-content">
              <img className="arrows-icon" src={ArrowsIcon} />
              <div className="step-text">{t('step')}</div>
            </div>
            <div className="step-right">
              <div className="step-top">
                <div className="number-text">3</div>
                <div className="buttom-text">{t('transcription')}</div>
              </div>
              <div className="step-bottom">{t('automatic')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Introduction;
